import React from "react"
import { Container } from "semantic-ui-react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Header from "../components/Header"
import Decoration from "../components/Decoration"

const NotFoundPage = () => (
  <Layout id="pages-dark">
    <SEO title="404 not found" description="This page cannot be found on the Slashdiablo private Diablo II server" />
    <Header active="support" />
    <Container text>
      <Decoration content="404" />
      <h1>404 NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
